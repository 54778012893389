import React, { createContext, useState, useContext, FC, useMemo } from 'react'

interface ActivityRecordContextState {
  activityAnswers: any
  addAnswer: (activityAnswer: any) => void
  resetAnswers: () => void
}

const ActivityRecordContext = createContext<ActivityRecordContextState | undefined>(undefined)

interface ActivityRecordProviderProps {
  children: React.ReactNode
}

const ActivityRecordProvider: FC<ActivityRecordProviderProps> = ({ children }) => {
  const [activityAnswers, setActivityAnswers] = useState<any[]>([])

  // Implement the addAnswer function
  const addAnswer = (activityAnswer: any) => {
    setActivityAnswers((prevActivityAnswers) => [...prevActivityAnswers, activityAnswer])
  }

  const resetAnswers = () => {
    setActivityAnswers([])
    console.log('activity answers', activityAnswers)
  }

  // Add the addAnswer function to the context value
  const value = useMemo(() => ({ activityAnswers, addAnswer, resetAnswers }), [activityAnswers])

  return <ActivityRecordContext.Provider value={value}>{children}</ActivityRecordContext.Provider>
}

const useActivityRecord = (): ActivityRecordContextState => {
  const context = useContext(ActivityRecordContext)
  if (!context) {
    throw new Error('useActivityRecord must be used within an ActivityRecordProvider')
  }
  return context
}

export { ActivityRecordProvider, useActivityRecord }
