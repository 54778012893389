import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { NextPageContext } from 'next'

export function clearRefreshToken(ctx: NextPageContext | null = null) {
  destroyCookie(ctx, 'refresh')
}
export function clearAccessToken(ctx: NextPageContext | null = null) {
  destroyCookie(ctx, 'access')
}

export function clearCookies(ctx: NextPageContext | null = null) {
  clearRefreshToken(ctx)
  clearAccessToken(ctx)
}

export function setAccessToken(access: string, ctx: NextPageContext | null = null) {
  setCookie(ctx, 'access', access, { maxAge: 9 * 60 })
}

export function setRefreshToken(refresh: string, ctx: NextPageContext | null = null) {
  setCookie(ctx, 'refresh', refresh, {
    maxAge: 30 * 24 * 60 * 60,
  })
}

export function setToppToken(bool: string, ctx: NextPageContext | null = null) {
  setCookie(ctx, 'is_topp', bool, {
    maxAge: 24 * 60 * 60,
  })
}

export function setAuthTokens(refresh: string, access: string, ctx: NextPageContext | null = null) {
  setRefreshToken(refresh, ctx)
  setAccessToken(access, ctx)
}

export function setLiteTokens(refresh: string, ctx: NextPageContext | null = null) {
  setRefreshToken(refresh, ctx)
}

export function getRefreshToken(ctx: NextPageContext | null = null) {
  const { refresh } = parseCookies(ctx)
  return refresh
}

export function getAccessToken(ctx: NextPageContext | null = null) {
  const { access } = parseCookies(ctx)
  return access
}

export function getAuthTokens(ctx: NextPageContext | null = null) {
  const refresh = getRefreshToken(ctx)
  const access = getAccessToken(ctx)
  return { refresh, access }
}
