import { IUserResponse, UserHook } from 'src/interfaces/auth'
import { ServerURL } from 'src/config'
import { fetcher } from '.'
import useSWR from 'swr'

export function useUserData(initialData?: IUserResponse): UserHook {
  const url = `${ServerURL}/users/me`
  const { isValidating, mutate, data, error } = useSWR(url, fetcher, {
    fallbackData: initialData,
    revalidateIfStale: false,
    revalidateOnMount: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryCount: 1,
  })
  function logout() {
    mutate(null)
  }
  return {
    error: error,
    loading: !data && !error,
    validating: isValidating,
    user: data,
    mutate,
    logout,
  }
}
