import { getAuthHeaders, getLMApiKey } from 'src/requests'
import axios from 'axios'

export const fetcher = async (url: string) => {
  const config = await getAuthHeaders()
  return axios.get(url, config).then((res) => res.data)
}

export const fetcherPost = (osp_id: string, module_identifier: string) => {
  return async (url: string) => {
    const config = await getAuthHeaders()
    return axios.post(url, { osp_id, module_identifier, name: 'Draft Parcel' }, config).then((res) => res.data)
  }
}

export const fetcherLM = (key: string) => {
  return async (url: string) => {
    const config = getLMApiKey(key)
    return axios.get(url, config).then((res) => res.data)
  }
}
