import { useState, useEffect, ReactNode } from 'react'
import { useErrorContext } from 'src/helpers/hoc/errorContext'

interface ErrorBoundaryProps {
  children: ReactNode
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false)
  const { logError } = useErrorContext()

  useEffect(() => {
    const unhandledRejectionListener = (event: PromiseRejectionEvent) => {
      logError(event.reason)
      setHasError(true)
    }

    window.addEventListener('unhandledrejection', unhandledRejectionListener)
    return () => {
      window.removeEventListener('unhandledrejection', unhandledRejectionListener)
    }
  }, [logError])

  if (hasError) {
    return <h1>Something went wrong. Please try again later.</h1>
  }

  return <>{children}</>
}

export default ErrorBoundary
