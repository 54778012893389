import { createContext, useContext, useState, useMemo } from 'react'

interface ErrorContextValue {
  error: Error | null
  setError: (error: Error | null) => void
  logError: (error: Error) => void
}

const ErrorContext = createContext<ErrorContextValue | null>(null)

export const useErrorContext = () => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useErrorContext must be used within an ErrorContext.Provider')
  }
  return context
}

interface ErrorProviderProps {
  children: React.ReactNode
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null)

  const logError = (error: Error) => {
    console.error('Logged error:', error)
    setError(error)
  }

  const contextValue = useMemo(
    () => ({
      error,
      setError,
      logError,
    }),
    [error, setError, logError],
  )

  return <ErrorContext.Provider value={contextValue}>{children}</ErrorContext.Provider>
}
