import { clearRefreshToken, getAuthTokens, setAccessToken } from '../utils/nookies'
import { refreshAccessToken } from './auth'
import { NextPageContext } from 'next'

export async function getAuthHeaders(ctx: NextPageContext | null = null) {
  try {
    let { access, refresh } = getAuthTokens(ctx)
    if (!access) {
      const response: any = await refreshAccessToken(refresh as string)
      setAccessToken(response.data.access)
      access = response.data.access
    }

    return {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    }
  } catch (error: any) {
    if (error?.response?.data?.message?.includes('blacklisted')) {
      clearRefreshToken()
    }
    throw error
  }
}

export function getLMApiKey(key: string) {
  return {
    headers: {
      'X-API-KEY': key,
    },
  }
}
