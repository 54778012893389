import { Toaster } from 'react-hot-toast'
import { useUserData } from 'src/helpers/hooks/user'
import { useNProgress } from '../utils/nprogress'
import { SSRProvider } from 'react-bootstrap'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import 'nprogress/nprogress.css'
import '../sass/globals.scss'
import { SWRConfig } from 'swr'
import ErrorBoundary from 'src/helpers/hoc/errorBoundary'
import { ErrorProvider } from 'src/helpers/hoc/errorContext'
import { ActivityRecordProvider } from 'src/context/ActivityRecordContext'
import { Provider } from 'jotai'
import Script from 'next/script'

function MyApp({ Component, pageProps }: AppProps) {
  useNProgress()
  const auth = useUserData()
  useEffect(() => {
    auth.mutate()
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(
          (registration) => {
            console.log('ServiceWorker registration successful with scope: ', registration.scope)
          },
          (err) => {
            console.log('ServiceWorker registration failed: ', err)
          },
        )
      })
    }
  }, [])

  return (
    <>
      <Script strategy='lazyOnload' src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} id='ga' />

      <Script strategy='lazyOnload' id='analytics'>
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>
      <ErrorProvider>
        <SSRProvider>
          <SWRConfig value={{ revalidateOnFocus: false }}>
            <ErrorBoundary>
              <Provider>
                <Toaster
                  position='top-center'
                  toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                      maxWidth: 800,
                      width: 'auto',
                      minWidth: 400,
                    },
                  }}
                />

                <ActivityRecordProvider>
                  <Component {...pageProps} />
                </ActivityRecordProvider>
              </Provider>
            </ErrorBoundary>
          </SWRConfig>
        </SSRProvider>
      </ErrorProvider>
    </>
  )
}

export default MyApp
