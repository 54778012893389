export const OSP_Wordflow = [
  '/images/OSP_Workflow/icon1.png',
  '/images/OSP_Workflow/icon2.png',
  '/images/OSP_Workflow/icon3.png',
  '/images/OSP_Workflow/icon4.png',
  '/images/OSP_Workflow/icon5.png',
  '/images/OSP_Workflow/icon6.png',
  '/images/OSP_Workflow/icon7.png',
  '/images/OSP_Workflow/icon8.png',
  '/images/OSP_Workflow/icon9.png',
  '/images/OSP_Workflow/icon10.png',
  '/images/OSP_Workflow/icon11.png',
  '/images/OSP_Workflow/icon12.png',
  '/images/OSP_Workflow/icon13.png',
  '/images/OSP_Workflow/icon14.png',
  '/images/OSP_Workflow/completed.png',
]

export const ServerURL = process.env.SERVER_API_URL
export const ToppURL = process.env.TOPP_API_URL
export const GoogleMapAPIKey = process.env.GOOGLE_MAP_API_KEY
