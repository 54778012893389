import { ServerURL, ToppURL } from 'src/config'
import axios from 'axios'
import { getRefreshToken } from 'src/utils/nookies'

export async function invitationValidationCheck(key: string) {
  return await axios.get(`${ServerURL}/user/invitation-validity-check/${key}`)
}

export async function resendActivationEmail(email: string) {
  return await axios.post(`${ServerURL}/user/resend-activation-email`, { email })
}

export async function resetPassword(email: string) {
  return await axios.post(`${ServerURL}/user/password-reset-request`, { email })
}

export async function loginWithEmailPassword(email: string, password: string) {
  return await axios.post(`${ServerURL}/token`, { email, password })
}

export async function signupUserWithCreds(data: any) {
  const start = Date.now()
  const resp = await axios.post(`${ServerURL}/users`, data)
  console.log('Elapsed time /users POST', Date.now() - start)
  return resp
}
export async function getToppLink() {
  const refresh = getRefreshToken(null)
  let url = ''

  if (window.location.href.includes('dev') || process.env.NODE_ENV == 'development') {
    url = process.env.NEXT_PUBLIC_OSP_LITE_DEV_URL || 'https://dev.app.osp-lite.quickorganics.com'
  } else if (window.location.href.includes('test') || process.env.NODE_ENV == 'test') {
    url = process.env.NEXT_PUBLIC_OSP_LITE_TEST_URL || 'https://test.app.osp-lite.quickorganics.com'
  } else {
    url = process.env.NEXT_PUBLIC_OSP_LITE_URL || 'https://topp.quickorganics.com'
  }
  window.open(url + '?refresh=' + refresh, '_blank')
}

export async function redirectToTopp(refresh: any, router: any) {
  let url = ''

  if (window.location.href.includes('dev') || process.env.NODE_ENV == 'development') {
    url = process.env.NEXT_PUBLIC_OSP_LITE_DEV_URL || 'https://dev.app.osp-lite.quickorganics.com'
  } else if (window.location.href.includes('test') || process.env.NODE_ENV == 'test') {
    url = process.env.NEXT_PUBLIC_OSP_LITE_TEST_URL || 'https://test.app.osp-lite.quickorganics.com'
  } else {
    url = process.env.NEXT_PUBLIC_OSP_LITE_URL || 'https://topp.quickorganics.com'
  }

  // Replace 'Authorization' and 'Bearer YOUR_ACCESS_TOKEN' with your custom headers.
  const headers = {
    refresh: refresh,
    // Add any other headers you need here.
  }

  try {
    // authentication
    const response = await axios
      .get(ToppURL as string, {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('Redirect to TOPP Res', res)
        return res
      })

      .catch((err) => {
        console.log('Redirect err ', err)
        return err
      })

    if (response?.status === 200) {
      // Successfully redirected, you can optionally handle the response here
      router.replace('tpp')
      setTimeout(() => {
        window.open(url + '?refresh=' + refresh, '_blank')
      }, 3000)
    } else {
      // Handle any errors or non-200 status codes here
      console.error('HTTP Error:', response.status)
    }
  } catch (error) {
    // Handle Axios errors here
    console.error('Axios Error:', error)
  }
}

export async function refreshAccessToken(refresh: string) {
  if (!refresh) return Error('No auth found.')
  return await axios.put(`${ServerURL}/token`, { refresh })
}

export async function sendResetPasswordRequest(key: string, password: string, confirm_password: string) {
  return await axios.post(`${ServerURL}/user/password-reset`, {
    key,
    password,
    confirm_password,
  })
}

export async function activateAccountEmail(activation_key: string) {
  return await axios.post(`${ServerURL}/user/activate`, {
    activation_key,
  })
}

export async function destroyRefreshToken(refresh: string) {
  return await axios.delete(`${ServerURL}/token`, { data: { refresh } })
}
